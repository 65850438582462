// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("./../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-photos-js": () => import("./../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-public-store-js": () => import("./../src/pages/public-store.js" /* webpackChunkName: "component---src-pages-public-store-js" */),
  "component---src-pages-vines-js": () => import("./../src/pages/vines.js" /* webpackChunkName: "component---src-pages-vines-js" */),
  "component---src-pages-wines-js": () => import("./../src/pages/wines.js" /* webpackChunkName: "component---src-pages-wines-js" */)
}

